import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../../constants';
import { createGroupsApi } from '../../services';
import { Analytics } from '../../services/analytics';
import { WarmupData } from '../../services/WarmupData';
import { hasMultiplePages } from '../../utils/multiple-pages';
import { Navigation } from '../PackagePicker/controller/Navigation';
import { RestrictedController } from '../PackagePicker/controller/RestrictedController';
import { Router } from '../PackagePicker/controller/Router';

export const createController: CreateControllerFn = async ({ flowAPI }: ControllerParams) => {
  const { setProps: untypedSetProps, wixCodeApi, appParams, compId } = flowAPI.controllerConfig;

  const setProps: (props: Partial<{}>) => void = untypedSetProps;

  const isMultiPage =
    flowAPI.experiments.enabled(EXPERIMENTS.INSTALL_SPLIT_PAGES) && (await hasMultiplePages(wixCodeApi));

  const groupsApi = createGroupsApi(flowAPI.httpClient);
  const analytics = new Analytics(wixCodeApi.window);
  const nav = new Navigation(wixCodeApi, flowAPI, appParams.appDefinitionId, isMultiPage);
  const router = new Router(setProps, nav, analytics, wixCodeApi, flowAPI);
  const warmupData = new WarmupData(compId, wixCodeApi, flowAPI);
  const restricted = new RestrictedController(setProps, wixCodeApi, router, groupsApi, flowAPI, warmupData);

  return {
    async pageReady() {
      await restricted.update(wixCodeApi.location.query.pageId ?? '', {});
    },
    updateConfig($w, updatedConfig) {},
  };
};
